.PopUpConfirm {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-radius: 12px;
  width: 200px;
  height: 150px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5px;

  span {
    height: calc(70%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .btnContainer {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      width: 45%;
      height: 100%;
      border: none;
      border-radius: 7px;
      background-color: rgb(45, 185, 47);
      transition: 0.2s;
      cursor: pointer;

      &:first-child {
        background-color: transparent;
        color: black;
        border: 1px solid rgb(45, 185, 47);
        &:hover {
          border-color: rgb(12, 77, 13);
        }
      }
      &:last-child {
        &:hover {
          background-color: rgb(12, 77, 13);
        }
      }
    }
  }
}
