.Sell {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 5px;

  span.title {
    font-size: 40px;
    color: white;
    margin-bottom: 30px;
  }
  div.Product {
    background-color: rgb(165, 165, 171);
  }
}

@media (min-width: 400px) {
  .Sell {
    padding: 20px;
  }
}
