.PopUpSell {
  width: 250px;
  height: 200px;
  background-color: white;
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .topBar {
    width: 100%;
    height: 60px;
    background-color: rgb(189, 189, 189);
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
  }

  label {
    padding: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    input {
      width: 50px;
    }
  }

  label.price {
    width: 100%;
    input {
      width: 100%;
    }
  }

  button {
    width: calc(100% - 10px);
    height: 40px;
    background-color: rgb(220, 217, 217);
    border: none;
    margin: 5px;
    border-radius: 7px;
  }
}
.Background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: absolute;
  left: 0;
  top: 0;
}

.bigger {
  height: 250px;
}
