.Product {
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 5px;
  border-radius: 12px;

  .name {
    font-size: black;
    flex: 1;
  }
  .right {
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 10px;
    border-radius: 7px;
    overflow: hidden;

    .date {
      width: 70px;
      height: 50px;
      background-color: rgb(199, 199, 199);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .price {
      min-width: 70px;
      padding: 2px;
      height: 50px;
      background-color: rgb(219, 219, 219);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .receipt {
      width: 50px;
      height: 50px;
      background-color: rgb(187, 55, 55);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s background-color;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .true {
      background-color: rgb(107, 190, 107);
    }
    .false {
      cursor: pointer;
      &:hover {
        background-color: rgb(255, 0, 0);
      }
    }

    .pieces {
      width: 50px;
      height: 50px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    button {
      width: 50px;
      height: 50px;
      background-color: rgb(229, 221, 221);
      border: none;
      font-size: 10px;
      transition: 0.2s background-color;

      &:hover {
        background-color: rgb(129, 119, 119);
      }
    }
    .edit {
      background-color: rgb(172, 123, 123);
      &:hover {
        background-color: rgb(208, 14, 14);
      }
    }
  }
}
