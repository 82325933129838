.LoginContainer {
  padding-top: 50px;
  width: 100%;
  min-height: 100vh;
  //   background-color: red;
  display: flex;
  flex-direction: column;
  //   justify-content: space-between;
  align-items: center;
  gap: 50px;

  form {
    color: white;
    width: 100%;
    // height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    // background-color: red;
    .title {
      //   color: white;
      font-size: 25px;
    }
    label {
      display: flex;
      flex-direction: column;
    }
  }
}

@media (min-width: 400px) {
  .LoginContainer {
    padding: 0;
    flex-direction: row;
  }
}
