.Add {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 100px);
  padding-top: 50px;

  form {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: white;
      width: 100%;

      .date {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        input {
          width: 130px;
        }
      }

      input {
        width: 300px;
        height: 30px;
        border: none;
        border-radius: 12px;
        padding: 5px;
      }
    }
    button {
      margin-top: 50px;
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: rgb(255, 255, 255);
      border: 2px solid black;
      border-radius: 12px;
      transition: 0.2s background-color;
      font-size: 22px;

      &:hover {
        background-color: rgb(209, 209, 209);
      }
    }
  }
}
