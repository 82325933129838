.Sold {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 5px;

  span.title {
    font-size: 40px;
    color: white;
    margin-bottom: 30px;
  }

  div.Product {
    background-color: rgb(224, 187, 187);
    .right {
      // width: 100px;
      button {
        display: none;
      }
    }
  }
}

@media (min-width: 400px) {
  .Sold {
    padding: 20px;
  }
}
