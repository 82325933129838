.Nav {
  width: 100%;
  height: 150px;
  background-color: rgb(81, 79, 79);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 5px 0;
  gap: 5px;

  .left {
    display: flex;
    align-items: center;
    gap: 10px;
    .logoutContainer {
      width: 30px;
      height: 30px;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s background-color;

      &:hover {
        background-color: white;
      }

      img {
        width: 90%;
        height: 90%;
        object-fit: contain;
      }
    }
    .title {
      font-size: 30px;
    }
  }

  .searchContainer {
    display: flex;
    gap: 10px;
    input {
      width: 200px;
      max-width: 300px;
      height: 30px;
      border-radius: 12px;
      padding: 10px;
      font-size: 20px;
      border: none;
    }
    select {
      border-radius: 12px;
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 2px;
    button {
      width: 40px;
      height: 40px;
      background-color: rgb(200, 200, 218);
      border: 1px solid rgb(255, 255, 255);
      cursor: pointer;
      border-radius: 5px;
      font-size: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 90%;
        height: 90%;
        object-fit: contain;
      }
    }
    .inactive {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

@media (min-width: 580px) {
  .Nav {
    padding: 0 30px 0 30px;
    gap: 30px;
    .title {
      font-size: 30px;
      font-weight: bold;
    }

    input {
      flex: 1;
    }

    .toggle {
      width: 100px;
      height: 40px;
    }
  }
}

@media (min-width: 580px) {
  .Nav {
    width: 100%;
    height: 100px;
    background-color: rgb(81, 79, 79);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
    gap: 5px;
  }
}
