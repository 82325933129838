.PopUpEditData {
  width: 200px;
  height: 250px;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  overflow: hidden;

  .topBar {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgb(189, 189, 189);
    font-size: 10px;
    font-weight: bold;
  }

  label {
    display: flex;
    flex-direction: column;
    width: calc(100% - 10px);
  }

  .date {
    width: calc(100% - 10px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    label {
      width: 45%;
    }
  }

  button {
    width: calc(100% - 10px);
    height: 30px;
    border-radius: 7px;
    margin-bottom: 5px;
    background-color: rgb(189, 189, 189);
    border: none;
  }
}
